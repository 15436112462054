import React, { Component } from 'react';
import moment from 'moment';
import history from '../history';

export default class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoggedIn: false,
			user: {}
		}
	}

	componentDidMount() {
		this.loadData();
	}

	loadData = async () => {
		var userD = await localStorage.getItem("userD");
		if (userD !== null) {
			userD = JSON.parse(userD);
			if (userD.user != undefined)
				this.setState({ isLoggedIn: true, user: userD });
		}
	};

	logout() {
	}

	render() {
		const { isLoggedIn, user } = this.state;
		return (
			<>
				{(isLoggedIn == true) &&
					<div id="JPO">
						<div className="m_header">My Account<span className="m_user-name">Hi, {(user.user != undefined) ? user.user.lastName + " " + user.user.firstName : ""}</span></div>

						<div className="account-assets">
							<a href="/profile"><i className="fa fa-file-text-o section-icons"></i>My Account Info</a>
							<a href="/resetpassword"><i className="fa fa-lock section-icons"></i>Change Login Password</a>
							{/* <a href="/logout" style={{ cursor: 'pointer' }}><i className="fa fa-power-off section-icons"></i>Logout</a> */}
						</div>

						<div className="_promo_banners"></div>

					</div>
				}
				<footer>
					<div className="grid grid-pad">
						<div className="m-footer-cnt">
							<div className="col-7-12">
								<div className="content"> 213 Games is licensed by the Gaming Commission of Ghana under License No. GCSB21H8627Q.
									<div className="adictive-under">
										<div className="player-age"><span>
											<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
												viewBox="0 0 126.61 126.36" style={{ enableBackground: 'new 0 0 126.61 126.36' }} xmlSpace="preserve">
												<path className="rang-player" d="M43.63,92.94h-7.55V56.7h-2.95v-1.02l3.97-14.12h6.53V92.94z M75.47,88.25c0,3.13-1.54,4.69-4.63,4.69H54.62
	c-3.04,0-4.56-1.56-4.56-4.69V73.31c0-1.91,0.63-3.36,1.9-4.37c1.51-0.87,3.03-1.75,4.56-2.63c-1.47-0.85-2.93-1.71-4.4-2.56
	c-1.33-1.05-2-2.46-2-4.23V46.3c0-3.15,1.55-4.73,4.66-4.73h15.99c3.04,0,4.56,1.58,4.56,4.73v13.23c0,1.79-0.66,3.21-1.97,4.23
	c-1.47,0.85-2.93,1.71-4.4,2.56c1.51,0.88,3.03,1.75,4.56,2.63c1.29,1.01,1.94,2.46,1.94,4.37V88.25z M67.82,86.48V72.23l-5.06-2.89
	l-5.09,2.89v14.25H67.82z M67.75,60.35V48.04h-9.94v12.31l4.96,2.95L67.75,60.35z M108.03,73.64H98.02v9.81h-6.99v-9.81H81.01v-6.37
	h10.01v-9.81h6.99v9.81h10.01V73.64z M107.54,14.86H22.11L0,0.54v88.54c0,20.43,16.56,36.99,36.99,36.99h70.55
	c9.93,0,17.98-8.05,17.98-17.98V32.84C125.53,22.91,117.48,14.86,107.54,14.86z"/>
											</svg>
										</span></div>
										<div className="player-warn">Players must be 18 or older to register or play at Level Up. Please note that gambles may have negative effects if not made with temperance.</div>
										<div className="social_connects">
											<h2>Social Connect</h2>
											<a href="https://www.facebook.com/213mobilegame"><i className="fa fa-facebook-f"></i></a><a href="https://www.instagram.com/213mobilegame/"><i className="fa fa-instagram"></i></a><a href="https://www.youtube.com/c/213games"><i className="fa fa-youtube-square"></i></a></div>
										<span className="footer-k-logo">
											<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 307.71 172.29" style={{ enableBackground: 'new 0 0 307.71 172.29' }} xmlSpace="preserve">
												<g>
													<g>
														<path className="in-0" d="M81.81,51.15c-2.26,3.27-4.73,6.75-7.4,10.44c-2.68,3.68-5.22,7.16-7.62,10.46c-2.4,3.28-4.47,6.13-6.21,8.56
			c-1.73,2.42-2.78,3.98-3.11,4.67c-0.51,1.09-0.95,3.08-1.3,5.94c-0.36,2.87-0.7,9.29-0.69,9.42c23.51-4.19,47.29-6.59,70.95-7.17
			c-0.13-2.35-0.44-4.61-0.9-6.77c-0.61-2.81-1.22-4.68-1.85-5.61c-0.43-0.65-1.37-1-2.82-1.06c-1.45-0.06-3.13,0.02-5.05,0.24
			c-1.92,0.21-3.93,0.48-6.03,0.8c-2.11,0.32-4.04,0.54-5.83,0.65c-1.78,0.1-3.25,0.08-4.42-0.09c-1.17-0.18-1.78-0.64-1.82-1.42
			c-0.05-0.77,0.44-1.81,1.48-3.13c1.03-1.32,2.35-2.91,3.97-4.81c1.62-1.88,3.37-4.06,5.25-6.54c1.88-2.48,3.64-5.24,5.29-8.29
			c1.64-3.04,3.07-6.36,4.24-9.96c1.18-3.59,1.88-7.52,2.11-11.78c0.47-12.77-2.26-21.77-8.23-27.01
			c-5.97-5.23-14.75-7.3-26.37-6.21c-6.25,0.57-11.51,2.05-15.8,4.44c-4.3,2.39-7.81,5.21-10.57,8.46
			c-2.74,3.25-4.75,6.68-6.03,10.28c-1.28,3.6-2.02,6.95-2.22,10.05c-0.19,3.1,0.09,5.72,0.84,7.84c0.76,2.12,1.81,3.24,3.17,3.35
			c0.88,0.15,2.28,0.24,4.23,0.33c1.93,0.07,3.91,0.07,5.93,0c2.02-0.07,3.87-0.24,5.55-0.49c1.67-0.24,2.64-0.62,2.9-1.12
			c0.25-0.6,0.4-1.37,0.43-2.34c0.04-0.96,0.07-1.96,0.11-2.98c0.03-1.01,0.17-1.96,0.41-2.85c0.23-0.89,0.73-1.54,1.47-1.97
			c1.77-1.07,3.44-1.73,5.02-1.97c1.58-0.24,3.01-0.21,4.28,0.1c1.27,0.31,2.3,0.87,3.07,1.69c0.77,0.82,1.18,1.71,1.24,2.68
			c0.05,0.67-0.66,2.21-2.11,4.62C85.92,45.03,84.06,47.87,81.81,51.15z M164.98,75.41c0.05-6.02,0.06-12.12,0.01-18.29
			c-0.04-6.16-0.14-12.23-0.28-18.21s-0.34-11.33-0.6-16.06c-0.26-4.72-0.56-8.6-0.89-11.64c-0.34-3.05-0.7-4.76-1.08-5.16
			c-0.85-0.78-2.79-1.44-5.82-1.97c-3.03-0.54-6.32-0.85-9.89-0.96c-3.56-0.1-6.96-0.01-10.19,0.27c-3.24,0.28-5.49,0.8-6.75,1.55
			c-0.88,0.56-1.63,2.24-2.25,5.03c-0.62,2.79-1.11,5.81-1.45,9.08c-0.35,3.27-0.48,6.3-0.37,9.1c0.1,2.8,0.43,4.44,1.01,4.93
			c0.66,0.49,1.7,0.46,3.1-0.1c1.41-0.55,2.89-1.16,4.44-1.81c1.54-0.64,2.96-1.16,4.21-1.51c1.26-0.37,2.03-0.11,2.3,0.76
			c0.18,0.49,0.09,1.93-0.29,4.33c-0.38,2.4-0.93,5.45-1.67,9.15c-0.74,3.69-1.55,7.83-2.44,12.39c-0.9,4.56-1.79,9.2-2.69,13.9
			c-0.9,4.7-1.73,9.34-2.47,13.91c-0.56,3.38-1.02,6.48-1.42,9.31c5.22-0.1,10.44-0.1,15.64-0.01c6.48,0.11,12.98,0.35,19.47,0.73
			c0.03-0.8,0.06-1.62,0.07-2.46C164.84,86.86,164.93,81.44,164.98,75.41z M224.19,60.1c2.2-0.87,4.66-2.13,7.4-3.78
			c2.73-1.65,5.31-3.83,7.75-6.53c2.42-2.69,3.94-6.12,4.56-10.27c0.52-4.16,0.57-8.11,0.17-11.84c-0.41-3.73-1.38-7.06-2.91-9.98
			c-1.53-2.93-3.62-5.37-6.3-7.34c-2.66-1.97-5.95-3.31-9.84-4.03c-3.61-0.68-7.45-1.19-11.54-1.54c-4.09-0.34-7.99-0.59-11.71-0.73
			c-3.73-0.14-7-0.15-9.83-0.02c-2.84,0.13-4.81,0.37-5.92,0.73c-0.8,0.19-1.57,0.94-2.32,2.25c-0.75,1.32-1.44,2.93-2.07,4.87
			c-0.63,1.93-1.2,4.02-1.73,6.26c-0.52,2.24-0.91,4.39-1.17,6.42c-0.25,2.02-0.4,3.82-0.45,5.37c-0.05,1.57,0.08,2.66,0.39,3.31
			c0.3,0.65,1.25,0.92,2.82,0.82c1.56-0.09,3.46-0.31,5.69-0.67c2.23-0.35,4.59-0.72,7.06-1.1c2.47-0.38,4.73-0.53,6.75-0.47
			c2.04,0.07,3.69,0.44,4.96,1.11c1.28,0.68,1.81,1.9,1.58,3.69c-0.33,2.68-1.47,4.59-3.41,5.74c-1.94,1.16-4.12,1.97-6.5,2.46
			c-2.4,0.49-4.73,0.91-7.01,1.26c-2.28,0.35-3.92,1.02-4.92,1.99c-0.99,0.97-1.8,2.38-2.42,4.21c-0.62,1.84-0.97,3.73-1.07,5.67
			c-0.11,1.95,0.09,3.77,0.55,5.44c0.48,1.67,1.29,2.89,2.46,3.65c1.17,0.75,2.9,1.85,5.2,3.25c2.3,1.42,4.54,2.95,6.71,4.6
			c2.17,1.65,4.02,3.38,5.54,5.2c1.52,1.81,2.19,3.52,1.99,5.11c-0.23,1.08-1.1,1.61-2.61,1.61c-1.5,0.01-3.36-0.26-5.58-0.81
			c-2.22-0.55-4.63-1.22-7.25-2.03c-2.62-0.8-5.13-1.49-7.54-2.06c-2.41-0.57-4.53-0.91-6.36-1c-1.83-0.09-3.06,0.32-3.65,1.25
			c-0.61,0.92-1.22,2.28-1.82,4.07c-0.61,1.78-1.18,3.74-1.68,5.89c-0.17,0.72-0.33,1.45-0.48,2.18c23.49,1.54,47.01,4.89,70.19,10
			c0.32-0.93,1.98-9.28,1.98-11.7c-0.04-4.34-1.05-8.22-3.06-11.66c-2-3.44-4.19-6.45-6.59-9.04c-2.4-2.59-4.59-4.69-6.57-6.31
			c-1.99-1.62-2.93-2.79-2.85-3.48C220.87,61.65,222,60.97,224.19,60.1z"></path>
														<g>
															<path className="in-0" d="M128.07,118.61l-0.05,0c0.02-0.07,0.03-0.13,0.04-0.21L128.07,118.61z"></path>
															<path className="in-0" d="M153.9,114.8l-0.02,0.61c-0.04,1.15-1.1,1.71-2.23,1.67c-0.63-0.02-1.35-0.37-1.32-1.05
				c0.04-1.26,1.98-1.28,2.87-1.25L153.9,114.8z"></path>
															<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="144.919" y1="168.9541" x2="144.919" y2="101.2211">
																<stop offset="0.0367" style={{ stopColor: "#fff" }}></stop>
																<stop offset="0.2572" style={{ stopColor: "#fff" }}></stop>
																<stop offset="0.4942" style={{ stopColor: "#fff" }}></stop>
																<stop offset="0.7382" style={{ stopColor: "#fff" }}></stop>
																<stop offset="0.9067" style={{ stopColor: "#fff" }}></stop>
															</linearGradient>
															<path className="in-1" d="M145.03,101.29c-27.05-0.45-54.14,1.34-81.08,5.35l1.93,11.11c1.51,8.67,2.25,17.44,2.23,26.22
				c0.01,0,0.01,0,0.01,0l0,0.08c0,1.13-0.02,2.26-0.05,3.38c0.14,10.2,3.31,21.2,23.47,19.46c8.85-0.99,18.05-1.74,27-2.2
				c8.95-0.47,17.64-0.66,25.45-0.53c7.82,0.13,16.49,0.6,25.42,1.37c8.93,0.76,18.11,1.81,26.91,3.09
				c20.09,2.41,23.63-8.48,24.1-18.67c0.01-1.12,0.03-2.25,0.06-3.38l0-0.08c0.28-8.77,1.31-17.51,3.1-26.13l2.29-11.04
				C199.1,104.42,172.08,101.74,145.03,101.29z M169.74,110.34l1.83,0.13l0.19-2.74l2.86,0.2l-0.19,2.74l2.51,0.18l-0.16,2.28
				l-2.51-0.18l-0.22,3.09c-0.07,0.99-0.01,1.72,1.22,1.81c0.3,0.02,0.92,0.02,1.18-0.19l-0.16,2.38c-0.65,0.19-1.36,0.19-2.02,0.15
				c-1.9-0.14-3.32-1.02-3.17-3.04l0.31-4.4l-1.83-0.13L169.74,110.34z M152.18,111.25c-0.94-0.02-1.73,0.37-2.36,1.04l-1.48-1.62
				c1.08-0.96,2.62-1.41,4.13-1.36c3.34,0.1,4.18,1.83,4.09,4.92l-0.14,4.72l-2.64-0.08l0.04-1.16l-0.03,0
				c-0.67,0.97-1.82,1.34-2.98,1.3c-1.74-0.05-3.27-1.09-3.22-2.96c0.1-3.21,3.99-3.14,6.38-3.07
				C153.99,111.95,153.17,111.28,152.18,111.25z M140.85,106.56l2.86,0.04l-0.03,2.75l2.52,0.03l-0.02,2.28l-2.52-0.03l-0.03,3.09
				c-0.02,0.99,0.09,1.72,1.33,1.73c0.31,0.01,0.92-0.03,1.17-0.25l-0.03,2.39c-0.63,0.22-1.34,0.27-2.01,0.26
				c-1.9-0.03-3.37-0.83-3.34-2.85l0.06-4.41l-1.83-0.02l0.02-2.29l1.84,0.02L140.85,106.56z M114.67,105.14
				c0.92-0.03,1.69,0.68,1.73,1.6c0.03,0.92-0.68,1.69-1.6,1.72s-1.69-0.67-1.72-1.59C113.04,105.96,113.75,105.18,114.67,105.14z
				 M116.66,118.93l-2.86,0.12l-0.37-9.27l2.86-0.12L116.66,118.93z M102.04,152.23c-0.05,0.81-0.15,1.45-0.25,1.92
				c-0.08,0.31-0.41,0.6-1,0.87c-0.59,0.27-1.34,0.5-2.24,0.69c-0.9,0.19-1.91,0.36-3.03,0.49c-1.12,0.13-2.23,0.24-3.35,0.31
				c-1.12,0.06-2.2,0.1-3.24,0.12c-1.04,0.01-1.91-0.01-2.64-0.05c-1.1-0.06-2.03-0.43-2.76-1.13c-0.74-0.69-1.34-1.57-1.82-2.66
				c-0.48-1.08-0.87-2.33-1.17-3.76c-0.3-1.42-0.55-2.88-0.75-4.4c-0.16-1-0.29-2.1-0.36-3.28c-0.07-1.18-0.1-2.38-0.09-3.57
				c0.01-1.2,0.09-2.37,0.22-3.51s0.32-2.16,0.56-3.09c0.25-0.93,0.56-1.72,0.95-2.38c0.39-0.65,0.88-1.1,1.45-1.33
				c0.57-0.22,1.34-0.41,2.32-0.54c0.98-0.13,2.05-0.23,3.22-0.29c1.17-0.06,2.38-0.07,3.62-0.04c1.24,0.04,2.41,0.1,3.49,0.19
				c1.08,0.08,2.02,0.21,2.81,0.35c0.79,0.15,1.34,0.33,1.63,0.54c0.44,0.32,0.74,0.87,0.93,1.66c0.18,0.79,0.27,1.61,0.26,2.46
				c-0.01,0.86-0.09,1.66-0.24,2.38c-0.15,0.73-0.34,1.19-0.55,1.38c-1,0.37-1.96,0.54-2.9,0.5c-0.93-0.03-1.82-0.13-2.67-0.28
				c-0.84-0.15-1.61-0.26-2.3-0.34c-0.7-0.09-1.3,0-1.78,0.26c-0.49,0.26-0.85,0.79-1.1,1.57c-0.24,0.78-0.33,1.98-0.27,3.58
				c0.03,0.92,0.09,1.82,0.19,2.71c0.1,0.88,0.25,1.67,0.46,2.37c0.22,0.7,0.49,1.26,0.82,1.71c0.33,0.44,0.74,0.67,1.23,0.68
				c0.49,0.03,0.84-0.1,1.05-0.39c0.22-0.3,0.37-0.68,0.45-1.13c0.07-0.45,0.09-0.96,0.04-1.5c-0.05-0.55-0.09-1.06-0.13-1.54
				c-0.09-0.71,0.05-1.27,0.42-1.68c0.36-0.41,0.83-0.73,1.42-0.95c0.58-0.21,1.23-0.34,1.94-0.39s1.38-0.06,1.99-0.03
				c0.62,0.02,1.14,0.07,1.56,0.13c0.43,0.06,0.67,0.11,0.72,0.13c0.18,0.09,0.35,0.41,0.48,0.96c0.14,0.55,0.24,1.24,0.33,2.07
				c0.09,0.82,0.14,1.73,0.17,2.7c0.03,0.98,0.03,1.95,0.02,2.9C102.15,150.54,102.11,151.41,102.04,152.23z M127.41,151.77
				c-0.04,0.91-0.16,1.47-0.36,1.71c-0.12,0.14-0.5,0.26-1.13,0.37c-0.64,0.11-1.34,0.2-2.12,0.27c-0.78,0.07-1.54,0.11-2.29,0.12
				c-0.75,0.02-1.32,0.01-1.7-0.03c-0.39-0.04-0.7-0.27-0.96-0.69c-0.26-0.42-0.48-0.91-0.67-1.46s-0.33-1.09-0.44-1.59
				c-0.11-0.5-0.18-0.86-0.21-1.06c-0.06-0.15-0.21-0.28-0.46-0.4c-0.25-0.12-0.52-0.18-0.83-0.18c-0.31-0.01-0.59,0.06-0.85,0.2
				c-0.26,0.14-0.43,0.4-0.48,0.78c-0.06,0.38-0.11,0.8-0.14,1.24c-0.04,0.45-0.09,0.87-0.14,1.27c-0.06,0.39-0.14,0.75-0.25,1.06
				s-0.27,0.52-0.5,0.63c-0.3,0.17-0.85,0.32-1.68,0.47c-0.82,0.14-1.69,0.24-2.6,0.27c-0.92,0.03-1.76-0.01-2.53-0.14
				c-0.77-0.12-1.25-0.36-1.44-0.71c-0.25-0.52-0.41-1.5-0.49-2.93c-0.08-1.42-0.09-3.08-0.03-4.97c0.06-1.89,0.17-3.88,0.35-5.99
				c0.18-2.11,0.39-4.1,0.65-5.99c0.26-1.88,0.55-3.53,0.87-4.94c0.31-1.41,0.64-2.36,0.98-2.86c0.19-0.26,0.64-0.49,1.33-0.68
				c0.7-0.19,1.51-0.34,2.47-0.44c0.95-0.1,1.97-0.17,3.05-0.21c1.08-0.04,2.12-0.06,3.11-0.04c1,0.02,1.88,0.05,2.64,0.09
				c0.77,0.05,1.31,0.1,1.61,0.17c0.36,0.09,0.73,0.54,1.1,1.34c0.37,0.8,0.74,1.84,1.1,3.11c0.35,1.28,0.69,2.72,1.02,4.32
				c0.33,1.61,0.62,3.25,0.89,4.96c0.26,1.7,0.48,3.37,0.66,5.03c0.18,1.66,0.31,3.16,0.4,4.51
				C127.44,149.74,127.45,150.87,127.41,151.77z M128.02,118.61l-2.81,0.07l-0.11-4.54c-0.03-1.07-0.08-2.44-1.55-2.41
				c-1.49,0.03-1.69,1.21-1.66,2.41l0.11,4.62l-2.86,0.07l-0.23-9.27l2.75-0.06l0.03,1.25l0.04,0c0.36-0.74,1.28-1.52,2.67-1.56
				c2.9-0.07,3.48,1.83,3.54,4.31l0.12,4.91l0,0.21L128.02,118.61z M130.16,112.21c0-0.11,0-0.21,0.02-0.31
				c0.06-0.8,0.41-1.42,0.92-1.87c0.74-0.66,1.84-0.96,2.94-0.97c1.22,0,2.58,0.29,3.44,1.2l-1.75,1.74
				c-0.42-0.51-0.98-0.78-1.66-0.78c-0.48,0.01-1.05,0.22-1.05,0.77c0.01,1.37,4.84,0.19,4.85,3.72c0.01,2.37-2.28,3.06-4.26,3.07
				c-1.3,0.01-2.73-0.31-3.66-1.24l1.73-1.93c0.53,0.64,1.17,1,2.03,1c0.65,0,1.3-0.18,1.3-0.67
				C135,114.46,130.17,115.74,130.16,112.21z M160.03,144.73c0,0.12,0,0.26-0.01,0.38c-0.04,1.39-0.1,2.65-0.18,3.8
				c-0.09,1.25-0.18,2.28-0.28,3.09c-0.1,0.81-0.22,1.31-0.35,1.49c-0.26,0.33-0.76,0.56-1.5,0.7c-0.41,0.08-0.83,0.13-1.26,0.15
				c-0.36,0.03-0.73,0.04-1.11,0.03c-0.83-0.01-1.62-0.08-2.36-0.2c-0.73-0.11-1.22-0.28-1.48-0.49c-0.18-0.13-0.27-0.52-0.29-1.18
				c-0.01-0.67,0.01-1.46,0.06-2.39c0.05-0.92,0.12-1.91,0.21-2.96c0.07-1.04,0.13-2,0.16-2.89c0.03-0.89,0.02-1.64-0.04-2.24
				c-0.05-0.6-0.19-0.93-0.42-0.98c-0.23-0.06-0.46,0.12-0.68,0.52c-0.22,0.41-0.45,0.92-0.68,1.56c-0.22,0.63-0.45,1.33-0.67,2.09
				c-0.22,0.76-0.44,1.49-0.67,2.18c-0.23,0.7-0.46,1.3-0.7,1.82c-0.24,0.52-0.48,0.83-0.7,0.96c-0.65,0.37-1.35,0.55-2.13,0.53
				c-0.78-0.01-1.5-0.22-2.15-0.61c-0.23-0.13-0.45-0.46-0.65-0.98c-0.21-0.52-0.41-1.15-0.61-1.85c-0.2-0.7-0.41-1.43-0.59-2.2
				c-0.19-0.77-0.39-1.47-0.59-2.11c-0.2-0.64-0.42-1.16-0.63-1.58c-0.21-0.41-0.43-0.59-0.66-0.55c-0.22,0.05-0.38,0.37-0.45,0.97
				c-0.08,0.6-0.11,1.34-0.12,2.23c0,0.89,0.02,1.86,0.07,2.9c0.04,1.05,0.07,2.03,0.1,2.96c0.02,0.93,0.01,1.72-0.02,2.38
				c-0.04,0.67-0.15,1.06-0.33,1.18c-0.26,0.2-0.76,0.34-1.49,0.43c-0.74,0.09-1.54,0.13-2.37,0.11c-0.84-0.01-1.63-0.1-2.36-0.27
				c-0.13-0.03-0.25-0.06-0.36-0.09c-0.54-0.17-0.91-0.39-1.12-0.66c-0.12-0.18-0.22-0.68-0.3-1.5c-0.04-0.44-0.08-0.95-0.11-1.52
				c-0.03-0.48-0.05-1.01-0.06-1.58c-0.05-1.25-0.06-2.64-0.04-4.18c0.01-1.54,0.03-3.11,0.08-4.72c0.04-1.6,0.11-3.17,0.2-4.73
				c0.09-1.55,0.19-2.96,0.31-4.21c0.11-1.26,0.24-2.31,0.37-3.14c0.15-0.84,0.29-1.36,0.45-1.56c0.34-0.37,0.87-0.65,1.6-0.81
				c0.73-0.16,1.5-0.25,2.31-0.26c0.82-0.01,1.61,0.03,2.36,0.14c0.77,0.1,1.35,0.22,1.75,0.35c0.28,0.09,0.58,0.41,0.9,1
				c0.32,0.57,0.66,1.26,1.01,2.08c0.36,0.81,0.71,1.67,1.08,2.57c0.36,0.91,0.71,1.76,1.05,2.54c0.33,0.78,0.63,1.44,0.9,1.96
				c0.27,0.53,0.51,0.79,0.71,0.8c0.2,0,0.46-0.25,0.75-0.76c0.31-0.52,0.63-1.16,0.99-1.93c0.36-0.77,0.74-1.6,1.14-2.51
				c0.39-0.89,0.79-1.74,1.17-2.54c0.38-0.79,0.74-1.47,1.08-2.04c0.35-0.56,0.66-0.88,0.94-0.96c0.41-0.12,0.99-0.21,1.76-0.29
				c0.76-0.07,1.55-0.09,2.37-0.05c0.81,0.04,1.58,0.16,2.3,0.35c0.72,0.19,1.25,0.47,1.57,0.87c0.15,0.2,0.28,0.73,0.39,1.57
				c0.11,0.83,0.2,1.89,0.26,3.16c0.07,1.25,0.12,2.67,0.15,4.22c0.04,1.56,0.05,3.14,0.04,4.74
				C160.1,141.62,160.06,143.2,160.03,144.73z M161.71,114.56l-0.24,4.61l-0.01,0l-2.85-0.15l0.41-7.8l0.07-1.46l2.74,0.14
				l-0.06,1.26l0.03,0c0.42-0.7,1.39-1.42,2.79-1.35c2.9,0.16,3.34,2.08,3.21,4.56l-0.27,5.11l-2.86-0.15l0.24-4.54
				c0.05-1.06,0.11-2.44-1.36-2.51C162.06,112.22,161.77,113.37,161.71,114.56z M184.91,131.02c-0.1,0.8-0.25,1.55-0.45,2.24
				c-0.2,0.7-0.39,1.16-0.56,1.38c-0.24,0.14-0.61,0.22-1.09,0.26c-0.49,0.04-1.03,0.07-1.65,0.07c-0.61,0.01-1.25,0-1.91-0.02
				c-0.66-0.02-1.28-0.04-1.85-0.06c-0.58-0.01-1.06-0.03-1.48-0.02c-0.4-0.01-0.67,0.02-0.77,0.09c-0.16,0.09-0.29,0.28-0.36,0.55
				c-0.09,0.28-0.14,0.58-0.16,0.91c-0.02,0.33-0.02,0.64,0.03,0.96c0.04,0.31,0.1,0.54,0.16,0.7c0.06,0.16,0.31,0.26,0.72,0.3
				c0.42,0.05,0.88,0.08,1.38,0.11c0.49,0.02,0.97,0.06,1.42,0.12c0.45,0.06,0.76,0.17,0.93,0.33c0.16,0.19,0.3,0.48,0.4,0.87
				c0.09,0.39,0.15,0.82,0.18,1.27c0.02,0.46-0.01,0.93-0.06,1.42c-0.07,0.48-0.18,0.88-0.36,1.2c-0.12,0.22-0.39,0.35-0.83,0.38
				c-0.44,0.03-0.93,0.06-1.48,0.08c-0.55,0.02-1.11,0.08-1.68,0.16c-0.57,0.09-1.04,0.3-1.39,0.63c-0.11,0.1-0.18,0.24-0.21,0.45
				c-0.03,0.2-0.03,0.42-0.03,0.64c0.01,0.23,0.03,0.48,0.06,0.74c0.04,0.26,0.07,0.49,0.1,0.69c0.01,0.21,0.22,0.37,0.64,0.47
				c0.41,0.11,0.93,0.18,1.56,0.22c0.62,0.04,1.3,0.07,2.04,0.09c0.73,0.01,1.45,0.05,2.13,0.09c0.68,0.05,1.3,0.12,1.84,0.21
				c0.54,0.09,0.92,0.22,1.14,0.39c0.21,0.17,0.35,0.57,0.43,1.19s0.09,1.31,0.07,2.04c-0.03,0.73-0.09,1.41-0.19,2.07
				c-0.09,0.66-0.2,1.09-0.32,1.28c-0.06,0.1-0.37,0.17-0.95,0.22s-1.32,0.09-2.23,0.11c-0.91,0.03-1.94,0.02-3.08-0.01
				c-1.15-0.04-2.32-0.07-3.51-0.12c-1.2-0.04-2.38-0.12-3.54-0.22c-1.17-0.09-2.22-0.21-3.17-0.34c-0.95-0.13-1.74-0.28-2.38-0.46
				c-0.63-0.18-1.03-0.36-1.16-0.55c-0.31-0.4-0.49-1.15-0.56-2.26c-0.07-1.1-0.08-2.43-0.02-4c0.07-1.57,0.19-3.3,0.37-5.2
				s0.39-3.85,0.62-5.85c0.22-2,0.41-3.72,0.56-5.15c0.16-1.42,0.41-2.59,0.75-3.48c0.34-0.89,0.87-1.54,1.57-1.91
				c0.7-0.38,1.72-0.56,3.04-0.51c1.15,0.03,2.43,0.07,3.84,0.12c1.41,0.05,2.81,0.14,4.19,0.27c1.38,0.12,2.67,0.28,3.85,0.48
				c1.18,0.2,2.12,0.48,2.81,0.84c0.34,0.17,0.56,0.6,0.64,1.29C185.02,129.48,185,130.22,184.91,131.02z M200.04,139.38
				c0.34,0.55,0.84,1.09,1.5,1.6c0.66,0.51,1.41,1.06,2.25,1.65c0.84,0.59,1.61,1.27,2.31,2.04c0.7,0.78,1.28,1.7,1.71,2.76
				c0.44,1.07,0.6,2.35,0.47,3.84c-0.15,1.68-0.75,3.07-1.8,4.19c-1.06,1.12-2.48,1.91-4.26,2.38c-1.79,0.47-3.9,0.58-6.35,0.34
				c-2.45-0.24-5.13-0.86-8.07-1.87c-0.59-0.22-0.99-0.71-1.21-1.46c-0.22-0.74-0.27-1.54-0.17-2.4c0.1-0.86,0.35-1.66,0.73-2.41
				c0.38-0.74,0.87-1.23,1.46-1.44c0.37-0.13,0.79-0.16,1.27-0.08c0.48,0.08,0.98,0.17,1.51,0.27c0.53,0.11,1.07,0.16,1.63,0.16
				c0.56,0,1.13-0.15,1.71-0.47c0.38-0.22,0.66-0.49,0.83-0.83c0.17-0.34,0.26-0.68,0.26-1.03c0.01-0.35-0.07-0.68-0.23-0.99
				c-0.15-0.31-0.36-0.56-0.62-0.74c-1.03-0.76-1.94-1.49-2.72-2.19c-0.77-0.69-1.43-1.38-1.97-2.05c-0.53-0.68-0.93-1.38-1.21-2.09
				c-0.28-0.71-0.44-1.48-0.5-2.31c-0.1-1.91,0.23-3.45,1.02-4.64c0.78-1.19,1.82-2.09,3.12-2.69c1.3-0.61,2.76-0.94,4.37-1
				c1.61-0.07,3.19,0.05,4.74,0.37c1.55,0.31,2.97,0.78,4.27,1.41c1.29,0.63,2.26,1.36,2.92,2.18c0.46,0.62,0.65,1.37,0.57,2.26
				c-0.08,0.89-0.47,1.7-1.17,2.44c-0.36,0.18-0.78,0.26-1.27,0.21c-0.49-0.04-1.01-0.14-1.55-0.3c-0.53-0.15-1.08-0.31-1.66-0.46
				c-0.57-0.16-1.1-0.23-1.6-0.23c-0.5,0.01-0.96,0.13-1.37,0.38c-0.42,0.24-0.75,0.69-1,1.32
				C199.66,138.19,199.69,138.82,200.04,139.38z"></path>
															<path className="in-0" d="M118.4,141.39c-0.01,0.46-0.26,0.82-0.74,1.08c-0.49,0.26-1.02,0.41-1.61,0.44
				c-0.59,0.03-1.12-0.05-1.61-0.25c-0.49-0.2-0.75-0.52-0.77-0.99c-0.01-0.15-0.01-0.49,0-1.05c0.01-0.54,0.02-1.19,0.05-1.91
				c0.02-0.73,0.05-1.48,0.09-2.28c0.03-0.79,0.07-1.51,0.13-2.14c0.04-0.41,0.23-0.73,0.59-0.96c0.37-0.22,0.76-0.36,1.21-0.38
				c0.45-0.03,0.86,0.06,1.26,0.28c0.38,0.21,0.62,0.55,0.68,1.01c0.1,0.79,0.21,1.47,0.3,2.07c0.1,0.6,0.18,1.16,0.24,1.69
				c0.06,0.53,0.1,1.07,0.13,1.6C118.38,140.13,118.4,140.73,118.4,141.39z"></path>
															<path className="in-0" d="M128.07,118.61l-0.05,0c0.02-0.07,0.03-0.13,0.04-0.21L128.07,118.61z"></path>
															<path className="in-0" d="M153.9,114.8l-0.02,0.61c-0.04,1.15-1.1,1.71-2.23,1.67c-0.63-0.02-1.35-0.37-1.32-1.05
				c0.04-1.26,1.98-1.28,2.87-1.25L153.9,114.8z"></path>
															<g>
																<path className="in-0" d="M128.07,118.4c-0.02,0.07-0.03,0.14-0.04,0.21l0.05,0L128.07,118.4z"></path>
															</g>
														</g>
													</g>
													<path className="in-2" d="M50.05,70.28c-0.23-0.52-0.74-0.98-1.55-1.36c-0.81-0.37-2.04-0.81-3.68-1.31c-1.38-0.48-2.58-0.88-3.58-1.21
		c-1.01-0.32-2.01-0.61-3-0.86c1.08-1.04,2.45-2.4,4.11-4.09c1.27-1.25,2.16-2.26,2.67-3.02c0.52-0.76,0.77-1.43,0.74-2.02
		c-0.03-0.58-0.28-1.13-0.74-1.64c-0.47-0.5-1.05-1.13-1.75-1.88c-0.82-0.87-1.48-1.58-2-2.12c-0.51-0.54-1.07-0.83-1.68-0.89
		c-0.6-0.06-1.28,0.16-2.03,0.65c-0.76,0.49-1.79,1.28-3.1,2.37c-1.1,0.96-2.06,1.78-2.89,2.45c-0.84,0.68-1.62,1.37-2.37,2.06
		c-0.18-0.71-0.39-1.49-0.61-2.36c-0.22-0.87-0.53-1.88-0.91-3.03c-0.43-1.66-0.85-2.88-1.27-3.7c-0.42-0.82-0.9-1.37-1.44-1.65
		c-0.53-0.28-1.16-0.35-1.88-0.2c-0.71,0.14-1.59,0.35-2.61,0.62c-1.26,0.33-2.27,0.56-3.02,0.72c-0.76,0.16-1.29,0.47-1.59,0.92
		c-0.3,0.46-0.45,1.1-0.44,1.94c0.01,0.83,0.19,2.09,0.54,3.76c0.19,1.38,0.39,2.59,0.62,3.61c0.22,1.03,0.42,2.04,0.59,3
		c-0.73-0.23-1.52-0.44-2.37-0.64c-0.85-0.2-1.89-0.43-3.14-0.7c-1.78-0.38-3.14-0.61-4.09-0.7c-0.95-0.09-1.67,0.01-2.18,0.31
		c-0.51,0.31-0.89,0.8-1.12,1.49c-0.24,0.69-0.53,1.54-0.85,2.55c-0.37,1.19-0.67,2.13-0.9,2.81c-0.24,0.7-0.25,1.31-0.03,1.84
		c0.22,0.52,0.73,0.98,1.55,1.35c0.81,0.38,2.03,0.82,3.67,1.31c1.36,0.4,2.54,0.77,3.56,1.09c1,0.33,2.01,0.66,3.02,0.98
		c-1.07,1.04-2.44,2.4-4.1,4.09c-1.27,1.25-2.18,2.27-2.74,3.04c-0.56,0.77-0.82,1.43-0.81,1.97c0.02,0.54,0.27,1.06,0.78,1.56
		c0.5,0.5,1.12,1.16,1.83,1.98c0.82,0.89,1.48,1.59,2,2.13c0.52,0.54,1.05,0.82,1.6,0.84c0.55,0.03,1.22-0.19,1.97-0.64
		c0.77-0.45,1.8-1.22,3.11-2.32c1.17-0.97,2.17-1.82,2.99-2.53c0.82-0.72,1.61-1.38,2.38-2.01c0.19,0.71,0.39,1.47,0.6,2.3
		c0.22,0.83,0.52,1.86,0.93,3.09c0.43,1.66,0.85,2.88,1.28,3.7c0.42,0.82,0.9,1.37,1.43,1.64c0.53,0.29,1.14,0.36,1.82,0.23
		c0.68-0.14,1.56-0.35,2.67-0.63c1.25-0.33,2.25-0.59,3.01-0.78c0.75-0.19,1.27-0.5,1.58-0.91c0.31-0.42,0.46-1.06,0.44-1.94
		c-0.02-0.87-0.19-2.11-0.52-3.7c-0.46-2.74-0.91-4.93-1.33-6.58c0.73,0.23,1.54,0.41,2.42,0.57c0.88,0.15,1.95,0.4,3.21,0.74
		c1.7,0.4,3.03,0.62,4.01,0.66c0.97,0.04,1.72-0.09,2.23-0.39c0.51-0.3,0.89-0.78,1.15-1.42c0.25-0.66,0.54-1.48,0.87-2.49
		c0.36-1.18,0.66-2.13,0.9-2.81C50.25,71.42,50.26,70.81,50.05,70.28z"></path>
													<path className="in-2" d="M305.59,65.18c-0.5-0.5-1.41-0.97-2.71-1.41c-1.3-0.44-3.19-0.93-5.68-1.45c0.62-2.23,1.01-3.94,1.14-5.14
		c0.14-1.19,0.05-2.07-0.25-2.64c-0.3-0.56-0.81-0.93-1.52-1.11c-0.71-0.18-1.61-0.41-2.71-0.67c-1.23-0.28-2.24-0.52-3.02-0.69
		c-0.78-0.17-1.49-0.07-2.11,0.31c-0.63,0.39-1.22,1.16-1.78,2.32c-0.56,1.16-1.26,2.91-2.09,5.24l-5.27-1.02
		c0.65-2.36,1.06-4.16,1.23-5.41c0.16-1.23,0.1-2.13-0.2-2.7c-0.3-0.56-0.84-0.9-1.63-1.03c-0.79-0.13-1.83-0.29-3.13-0.5
		c-1.12-0.17-2.06-0.3-2.8-0.4c-0.75-0.09-1.44,0.06-2.07,0.47c-0.63,0.4-1.23,1.16-1.81,2.27c-0.57,1.11-1.24,2.79-2.01,5.04
		c-2.61-0.41-4.58-0.64-5.93-0.72c-1.35-0.07-2.37,0.04-3.05,0.34c-0.68,0.3-1.16,0.76-1.43,1.4c-0.27,0.64-0.58,1.43-0.95,2.35
		c-0.41,1.12-0.73,2.01-0.95,2.67c-0.23,0.67-0.12,1.27,0.31,1.78c0.43,0.51,1.3,0.99,2.6,1.43c1.3,0.44,3.26,0.97,5.88,1.57
		c-0.24,0.77-0.49,1.52-0.72,2.23c-0.23,0.72-0.49,1.54-0.76,2.45c-2.7-0.42-4.75-0.68-6.15-0.75c-1.39-0.08-2.43,0.03-3.11,0.33
		c-0.68,0.3-1.13,0.79-1.33,1.47c-0.2,0.68-0.44,1.59-0.71,2.73c-0.23,0.96-0.43,1.77-0.58,2.44c-0.15,0.67-0.01,1.26,0.42,1.77
		c0.43,0.51,1.25,0.99,2.49,1.42c1.23,0.43,3.09,0.91,5.58,1.44c-0.67,2.22-1.09,3.95-1.26,5.18c-0.17,1.24-0.12,2.17,0.14,2.8
		c0.26,0.62,0.76,1.04,1.48,1.25c0.72,0.22,1.67,0.41,2.84,0.58c1.67,0.28,3,0.38,4,0.31c0.99-0.07,1.82-0.37,2.47-0.89
		c0.65-0.53,1.18-1.32,1.61-2.38c0.42-1.07,0.92-2.46,1.5-4.17l4.64,0.9c-0.52,1.72-0.9,3.13-1.14,4.24c-0.23,1.1-0.23,2,0.02,2.7
		c0.24,0.7,0.8,1.26,1.65,1.69c0.85,0.43,2.11,0.83,3.77,1.19c1.1,0.26,2.04,0.43,2.81,0.51c0.77,0.08,1.45-0.1,2.05-0.54
		c0.58-0.44,1.17-1.23,1.75-2.36c0.58-1.14,1.23-2.78,1.93-4.95c2.46,0.43,4.38,0.68,5.79,0.76c1.39,0.08,2.46-0.02,3.18-0.29
		c0.72-0.27,1.22-0.72,1.48-1.37c0.27-0.64,0.55-1.46,0.84-2.46c0.39-1.5,0.56-2.7,0.55-3.56c-0.03-0.87-0.32-1.56-0.9-2.08
		c-0.57-0.51-1.46-0.94-2.67-1.27c-1.21-0.33-2.77-0.7-4.7-1.13c0.24-0.76,0.46-1.48,0.66-2.13c0.2-0.66,0.4-1.3,0.63-1.93
		c1.94,0.38,3.54,0.64,4.8,0.79c1.27,0.14,2.31,0.1,3.12-0.15c0.81-0.25,1.49-0.75,2.02-1.51c0.52-0.75,1.05-1.86,1.58-3.34
		c0.38-0.98,0.63-1.8,0.76-2.46C306.27,66.27,306.09,65.69,305.59,65.18z M280.21,73.15c-0.21,0.61-0.42,1.23-0.64,1.85
		c-0.74-0.14-1.46-0.26-2.15-0.38c-0.7-0.1-1.4-0.23-2.09-0.36c0.23-0.67,0.43-1.33,0.59-1.97c0.18-0.64,0.34-1.27,0.52-1.91
		c0.74,0.14,1.46,0.28,2.18,0.42c0.72,0.14,1.46,0.3,2.24,0.51C280.64,71.93,280.42,72.55,280.21,73.15z"></path>
												</g>
											</svg>
										</span> </div>
								</div>
							</div>
							<div className="col-5-12">
								<div className="content footer-nav">
									<ul>
										<li><a href="/promo">Promotions</a></li>
										<li><a href="/tnc">T&Cs</a></li>
										<li><a href="/contact">Contact us</a></li>
									</ul>
									<div className="m_customer_service">
										<h2 className="m_footer_header">Customer Service</h2>
										<img src="ui/images/bf1235156a2d68310304906bb447685c.png" /><span>Help Line: <i className="fa fa-mobile-phone"></i> 0308 249 479 </span> </div>
									<div className="m_customer_service m_payment_app">
										<h2 className="m_footer_header">Payment Method</h2>
										<img src="ui/images/mtn.png" alt="MTN" title="MTN" />
										<img src="ui/images/airtel.png" alt="Airteltigo" title="Airteltigo" />
										<img src="ui/images/vodafone_logo.png" alt="Vodafone" title="Vodafone" />
									</div>
								</div>
							</div>
						</div>
						<div className="m_copy_right">&copy;&nbsp;{moment().format('YYYY')} Games Technology. All right reserved. v1.0.2</div>
					</div>
				</footer>
			</>
		);
	}
}
